@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.img_wrap {
	position: relative;
	width: 100%;
	@include flexCenter;

	img {
		position: relative !important;
		object-fit: contain;
	}
}
