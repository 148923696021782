// // styles/YouTubeModal.module.scss

// .modal {
// 	display: none;
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	background-color: rgba(0, 0, 0, 0.7);
// 	z-index: 1000;

// 	&.open {
// 		display: block;
// 	}

// 	.modal_content {
// 		width: 460px;
// 		height: 230px;
// 		position: absolute;
// 		top: 50%;
// 		left: 50%;
// 		transform: translateY(100%) translateX(-50%);
// 		background-color: #fefefe;
// 		padding: 20px;
// 		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
// 		transition: transform 0.3s ease-out;
// 		z-index: 1001;

// 		.close {
// 			color: #aaa;
// 			float: right;
// 			font-size: 28px;
// 			font-weight: bold;
// 			cursor: pointer;

// 			&:hover,
// 			&:focus {
// 				color: black;
// 			}
// 		}
// 	}

// 	&.open .modal_content {
// 		transform: translateY(-50%) translateX(-50%);
// 	}
// }

.iframe {
	width: 100%;
	height: 100%;
}
