@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.selectlist {
	position: relative;
	z-index: 1000; // or another high value
	.input {
		width: 100%;
		height: 48px;
		border: 1px solid getColor(grey300);
		border-radius: 5px;
		padding: 0 10px;
		transition:
			border 0.1s ease-in,
			outline 0.1s ease-out;
		&:focus {
			border: 2px solid getColor(primary);
			outline: none;
		}
		&::placeholder {
			text-align: center;
			display: flex;
			align-items: center;
		}
	}
	.option_list_warp {
		position: absolute;
		top: -20%;
		left: 0;
		min-width: 100%;
		max-height: 300px;
		overflow-y: auto;
		background-color: white;
		z-index: 999;
		box-shadow:
			0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14),
			0px 3px 14px 2px rgba(0, 0, 0, 0.12);
		border-radius: 4px;
		animation: slide-down 0.3s ease-in-out;
		@keyframes slide-down {
			0% {
				opacity: 0;
				transform: translateY(-10px);
			}
			100% {
				opacity: 1;
				transform: translateY(0px);
			}
		}
		//close animation
		&.close {
			animation: slide-up 0.3s ease-in-out;
			@keyframes slide-up {
				0% {
					opacity: 1;
					transform: translateY(0px);
				}
				100% {
					opacity: 0;
					transform: translateY(-10px);
				}
			}
		}

		.menuItems {
			cursor: pointer;
			font-size: 14px;
			text-wrap: nowrap;
			overflow-x: hidden;
			font-weight: 400;
			color: getColor(grey900);

			&:not(:last-child) {
				border-bottom: 1px solid getColor(grey300);
			}
		}

		// scroll bar
		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: getColor(primary); /* Color of the scroll thumb */
			border-radius: 20px; /* Roundness of the scroll thumb */
		}
	}
	.positionUpwards {
		top: auto;
		bottom: 100%; // Position it above the trigger element
		// Other necessary styling adjustments
	}
}

.active {
	@include bgColor(primary, 0.1);
	color: getColor(primary);
}
.close {
	display: none;
	animation: slide-up 0.3s ease-in-out;
	@keyframes slide-up {
		0% {
			opacity: 1;
			transform: translateY(0px);
		}
		100% {
			opacity: 0;
			transform: translateY(-10px);
		}
	}
}
.outOfStockClass {
	font-weight: 500;
}
