@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
$topCategoriesColor: portage_bg, babypink_bg, portage_bg, sky_bg, error_bg;
@function get-next-color($current-index) {
	$total-colors: length($topCategoriesColor);
	$next-index: $current-index % $total-colors + 1;
	@return getColor(nth($topCategoriesColor, $next-index));
}
$max-slides: 20;

.category_card_wrapper {
	//modifying swiper styles
	border-radius: 6px;

	.category_card {
		display: flex;
		height: 100%;
		border-radius: 10px;
		height: 151px;
		width: 151px;

		.category_card__image {
			border-radius: 6px 6px 0 0;
			align-items: center;
			display: flex;
			justify-content: center;

			img {
				width: 151px;
				height: 151px;
				object-fit: contain;
			}
		}
	}
	.category_card__content {
		align-items: center;
		height: 100%;
		padding: 12px;
		cursor: pointer;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 151px;
		height: 42px;
		font-size: 14px;
		font-weight: 500;
		text-align: center;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;
		line-height: 106%;
		color: getColor(grey700);
	}
}
.card_grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
}
.card_grid {
	grid-template-columns: repeat(2, 1fr);
}

/* Medium devices (tablets, 576px and up) */
	@media (min-width: 576px) {
		.card_grid {
			grid-template-columns: repeat(3, 1fr);
		}
		
	}
	@media (min-width: 768px) {
		.card_grid {
			grid-template-columns: repeat(4, 1fr);
			gap: 5px;
		}
		
	}
	@media (min-width: 800px) {
		.card_grid {
			grid-template-columns: repeat(4, 1fr);
		}
		
	}
	@media (min-width: 1024px) {
		.card_grid {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	@media (min-width: 1100px) {
		.card_grid {
			grid-template-columns: repeat(4, 1fr);
		
		}
	}

	@media (min-width: 1200px) {
		.card_grid {
			grid-template-columns: repeat(5, 1fr);
			grid-gap: 5px;
		}
	}
	@media (min-width: 1280px) {
		.card_grid {
			grid-template-columns: repeat(5, 1fr);
			grid-gap: 5px;
		}
	}

	@media (min-width: 1400px) {
		.card_grid {
			grid-template-columns: repeat(6, 1fr);
		}
	}

	/* Extra large devices (1600px and up) */
	@media (min-width: 1600px) {
		.card_grid {
			grid-template-columns: repeat(8, 1fr);
		}
	}
