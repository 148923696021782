@import '@/services/scss/variables.scss';

.action_side_scroll_underline {
	.card {
		border-radius: 6px;
		.card_top {
			height: 100px;
			padding: 20px;
			.card_title {
				font-size: 18px;
				font-weight: 600;
				color: getColor(grey900);
				padding-bottom: 8px;
			}
			.card_subtitle {
				padding-top: 8px;
				font-size: 14px;
				font-weight: 400;
				color: getColor(grey900);
			}
		}
		.card_bottom {
			width: 100%;
			height: 100%;
			margin-top: 50px;
			& img {
				width: 100%;
			}
		}
	}
}
