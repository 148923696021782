.FlashSale {
	&.large {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background-image: url('../../../assets/svg/FlashSale.svg');
		background-size: contain;
		background-repeat: no-repeat;
		height: 20px;
		width: 90px;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			position: relative;
			font-size: 12px;
			color: white;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
			text-align: center;
			margin-left: 5px;
		}
	}
	&.small {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background-image: url('../../../assets/svg/FlashSale.svg');
		background-size: contain;
		background-repeat: no-repeat;
		height: 18px;
		width: 80px;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			position: relative;
			font-size: 9px;
			color: white;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
			text-align: center;
			margin-left: 3px;
		}
	}
}
.DiscountLabel {
	&.large {
		position: absolute;
		background-image: url('../../../assets/svg/pdiscount.svg');
		background-size: contain;
		background-repeat: no-repeat;
		height: 30px;
		width: 30px;
		top: 0;
		left: 10px;
		span {
			position: relative;
			font-size: 10px;
			color: white;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
			text-align: center;
		}
	}
	&.small {
		position: absolute;
		background-image: url('../../../assets/svg/pdiscount.svg');
		background-size: contain;
		background-repeat: no-repeat;
		height: 25px;
		width: 25px;
		top: 0;
		left: 10px;
		span {
			position: relative;
			font-size: 8px;
			color: white;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
			text-align: center;
		}
	}
}
