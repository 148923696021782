.sidescroll_up_to_money_range {
	cursor: pointer;
	.card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		border-radius: 6px;
		position: relative;
		.card__image {
			border-radius: 12px;
			align-items: center;
			display: flex;
			padding: 18px;

			img {
				width: 178px;
				height: 178px;
				border-radius: 6px 6px 0 0;
			}
		}
		.card__content {
			position: absolute;
			right: 0;
			top: 25%;
			height: 100px;
			background-color: red;
			width: 50%;
			border-radius: 28px 0px 0px 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			color: getColor(white);
		}
	}
}
