.most_search_on {
	width: 100%;
	.header {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
		margin-bottom: 40px;

		.head {
			width: 440px;
			border-radius: 6px;
			border: 1px solid var(--others-portage, #8c62ff);
			background: var(--greyscale-200, #f1f2f4);
			padding: 12px;
			display: flex;
			justify-content: space-between;
			@keyframes typing {
				0%,
				25% {
					width: 0;
				}
				50%,
				75% {
					width: 100%;
				} // 50% to 75% will be the pause
				100% {
					width: 0;
				}
			}

			.title {
				font-size: 16px;
				font-weight: 400;
				color: getColor(grey700);
			}
			.icon {
				font-size: 16px;
				color: var(--others-portage, #8c62ff);
			}
		}
	}
	.card {
		display: flex;
		height: 100%;
		border-radius: 6px;
		align-items: center;
		gap: 20px;
		position: relative;
		.card__image {
			height: 70px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 6px 6px 0 0;
			}
		}
		.icon {
			position: absolute;
			top: 30%;
			right: 10px;
			font-size: 16px;
			color: getColor(portage);
			svg {
				width: 30px;
				height: 30px;
			}
		}
	}
}
