.k_beauty {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px 20px;
		margin-bottom: 25px;
		border-radius: 6px;
	}
	.card {
		.card__image {
			border-radius: 12px;
			align-items: center;
			display: flex;
			justify-content: center;
			img {
				width: 220px;
				height: 220px;
				object-fit: cover;
				border-radius: 6px 6px 0 0;
			}
		}
		.category_card__content {
			padding-block: 20px;
			border-radius: 0 0 6px 6px;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			height: 100%;
			gap: 7px;
			font-size: 18px;
		}
	}
}
