@import '@/services/scss/variables.scss';
.life_style {
	.card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		border-radius: 6px;
		background-color: #fff;
		border: 1px solid getColor(grey300);
		.card__image {
			border-radius: 12px;
			align-items: center;
			display: flex;
			justify-content: center;

			img {
				width: 100%;
				height: 264px;
				object-fit: cover;
				border-radius: 6px 6px 0 0;
			}
		}
		.category_card__content {
			padding-block: 20px;
			background-color: getColor(white);
			border-radius: 0 0 6px 6px;
			display: flex;
			justify-content: center;
			height: 100%;
			gap: 7px;
			font-size: 18px;
		}
	}
}
