.featured_brands {
	border-radius: 6px;

	.featured_brand_item {
		height: 218px;
		width: 218px;
		border-radius: 6px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 53.5px;
	}
}
