@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.product_wrap {
	background: #ffffff;
	border: 1px solid #eeeff2;
	border-radius: 6px;
	height: 100%;

	a {
		color: black;
	}

	.product {
		// padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		.img_wrap {
			@include flexCenter;
			position: relative;
			min-height: 120px;
			width: 100%;

			.out_off_stock {
				@include absolute(0, auto, auto, 0);
				background-color: $white;
				z-index: 3;
				font-size: 10px;
				color: $error;
				border-radius: 0px 12px 12px 0px;
				box-shadow: 1px 0 1px $error;
				padding-left: 0;
				font-weight: 700;
				list-style: none;
			}

			img {
				object-fit: contain;
				border-radius: 10px 10px 0px 0px;
			}
		}

		.product_details {
			@include flexColBetween;
			height: 100%;
			padding-top: 10px;
			padding-inline: 10px;
			word-wrap: break-word;
			max-height: 186px;
			h3 {
				max-width: 100%;
				font-size: 16px;
				font-weight: 300;
				@include color(grey900);
				margin-bottom: 10;
				white-space: wrap;
				line-height: 24px;
				min-height: 48px;
				height: 48px;
				overflow: hidden;
				text-overflow: ellipsis;

				span {
					color: #a0aec0;
				}
			}

			.price {
				position: relative;

				del {
					@include color(grey500);
					font-weight: 400;
					font-size: 14px;
				}
				b {
					color: #fd6a6a;
					font-weight: 700;
					font-size: 16px;
				}
				h4 {
					font-weight: 600;
					font-size: 16px;
					margin-bottom: 0;
				}

				.addBtn {
					@include absolute(auto, 0, 0, auto);
				}
			}
		}
	}
}
