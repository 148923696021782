.shop_by_concern {
	.card {
		display: grid;

		grid-template-columns: repeat(4, 1fr);
		gap: 50px;
		.cardbody {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			border-radius: 6px;
			.card__image {
				border-radius: 12px;
				align-items: center;
				display: flex;
				justify-content: center;

				img {
					width: 230px;
					height: 230px;
					object-fit: cover;
					border-radius: 6px 6px 0 0;
				}
			}
			.category_card__content {
				padding-block: 20px;
				border-radius: 0 0 6px 6px;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				height: 100%;
				gap: 7px;
				font-size: 18px;
			}
		}
	}
	@media (min-width: 576px) {
		.card {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	@media (min-width: 1024px) {
		.card {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	
	@media (min-width: 1100px) {
		.card {
			grid-template-columns: repeat(2, 1fr);
		
		}
	}
	
	@media (min-width: 1200px) {
		.card {
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 5px;
		}
	}
	@media (min-width: 1280px) {
		.card {
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 5px;
		}
	}
	
	@media (min-width: 1400px) {
		.card {
			grid-template-columns: repeat(4, 1fr);
		}
	}
	
	/* Extra large devices (1600px and up) */
	@media (min-width: 1600px) {
		.card {
			grid-template-columns: repeat(4, 1fr);
		}
	}
	
}



