.shop_by_category {
	//modifying swiper styles
	border-radius: 6px;
	.card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		border-radius: 6px;
		.card__image {
			border-radius: 12px;
			align-items: center;
			display: flex;
			justify-content: center;

			img {
				width: 113px;
				height: 113px;
				object-fit: cover;
				margin-block: 10px;
			}
		}
		.category_card__content {
			border-radius: 0 0 6px 6px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			height: 100%;
			padding: 12px;
			gap: 7px;
			//hover
		}
	}
}
