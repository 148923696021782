.health_supplements {
	.card {
		display: flex;
		border-radius: 10px;
		height: 235px;
		width: 496px;

		.card__content {
			display: flex; /* or use 'grid' if you prefer grid layout */
			justify-content: space-between; /* only relevant for flex layout */
			gap: 30px;
			padding-inline: 15px;

			.card__left {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 10px;
				padding-inline: 15px;
				gap: 20px;
				width: 50%;
				/* grid-area: left;  Uncomment if using grid layout */
			}

			.card__right {
				border-top-right-radius: 90px;
				border-top-left-radius: 90px;
				margin-top: 10px;
				width: 191px;
				display: flex;
				justify-content: center;
				align-items: center;

				/* grid-area: right;  Uncomment if using grid layout */
			}
		}
	}
}

.card__button {
	&:hover {
		opacity: 0.8;
	}
}
