@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
$topCategoriesColor: portage_bg, babypink_bg, portage_bg, sky_bg, error_bg;
@function get-next-color($current-index) {
	$total-colors: length($topCategoriesColor);
	$next-index: $current-index % $total-colors + 1;
	@return getColor(nth($topCategoriesColor, $next-index));
}
$max-slides: 20;

.category_card_wrapper {
	//modifying swiper styles
	border-radius: 6px;
	.category_card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		border-radius: 12px;
		.category_card__image {
			border-radius: 6px 6px 0 0;
			align-items: center;
			display: flex;
			justify-content: center;

			img {
				width: 113px;
				height: 113px;
				object-fit: cover;
				margin-block: 10px;
			}
		}
		.category_card__content {
			background-color: getColor(white);
			border-radius: 0 0 6px 6px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			height: 100%;
			padding: 12px;
			gap: 7px;
		}
	}
}
