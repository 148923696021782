@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
.actions_wrap {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 10px;
	grid-auto-rows: minmax(100px, auto);
}
@media (max-width: 1600px) {
	.actions_wrap {
		grid-template-columns: repeat(5, 1fr);
	}
}
@media (max-width: 1348px) {
	.actions_wrap {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (max-width: 1200px) {
	.actions_wrap {
		grid-template-columns: repeat(3, 1fr);
	}
}
// media query for 900px
@media (max-width: 900px) {
	.actions_wrap {
		grid-template-columns: repeat(2, 1fr);
	}
}
@media (max-width: 576px) {
	.actions_wrap {
		grid-template-columns: repeat(1, 1fr);
	}
}

.actions {
	// min-width: 15.66%;
	// max-width: 15.66%;
	height: 220px;
	margin-right: 10px;
	position: relative;
	border-radius: 12px 101.919px 12px 12px;
	padding: 10px;

	&:last-child {
		margin-right: 0;
	}

	.icon_wrap {
		@include absolute(0, 0);
	}

	.content {
		@include flexColBetween;
		height: 100%;
		line-height: 24px;

		& > div {
			padding-top: 30px;
		}

		p {
			margin: 0;
			margin-bottom: 5px;
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 10px;

			b {
				font-size: 18px;
			}
		}

		h5 {
			font-size: 18px;
			line-height: 150%;
			font-weight: 700;
		}

		.button_wrap {
			cursor: pointer;
			background-color: getColor(white);
			padding: 0px;
			height: 48px;
			font-size: 16px;
			justify-content: center;
			border-radius: 12px;
			font-weight: 700;
			display: flex;
			width: 100%;
			align-items: center;
			&:hover {
				background-color: getColor(primary_bg);
			}
		}
	}
}
