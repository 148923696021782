@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.product_wrap {
	background: white;
	border-radius: 10px;
	height: 100%;
	position: relative;
	border: 1px solid getColor(grey300);

	a {
		color: black;
	}

	.product {
		// padding: 10px;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		//hover
		&:hover {
			box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
			transition: all 0.3s ease-in-out;
			// border-radius: 10px;
			.img_wrap {
				img {
					transform: scale(1.1);
					transition: all 0.3s ease-in-out;
					border-radius: 10px;
					overflow: hidden;
				}
			}
		}

		.img_wrap {
			@include flexCenter;
			position: relative;
			min-height: 120px;
			width: 100%;
			overflow: hidden;
			background-color: white;
			border-radius: 10px 10px 0px 0px;
			.discount {
				position: absolute;
				background-image: url('../../../assets/svg/pdiscount.svg');
				background-size: contain;
				background-repeat: no-repeat;
				height: 30px;
				width: 30px;
				top: 0;
				left: 10px;
				span {
					position: relative;
					font-size: 10px;
					color: white;
					font-weight: 600;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100%;
					width: 100%;
					text-align: center;
				}
			}

			img {
				max-height: 200px;
				// max-width: 186px;
				// min-height: 186px;
				// min-width: 186px;
				width: 100%;
				object-fit: contain;
				border-radius: 10px 10px 0px 0px;
			}
		}

		.product_details {
			@include flexColBetween;
			height: 100%;
			padding-top: 10px;
			padding-inline: 10px;
			word-wrap: break-word;
			max-height: 186px;
			.delevery_info {
				background-color: #f1f2f4;
				border-radius: 3px;
				display: flex;
				align-items: center;
				gap: 5px;
				padding: 5px;
				width: fit-content;
				font-size: 8px;
				font-weight: 600;
				margin-bottom: 10px;
				cursor: pointer;
				svg {
					width: 15px;
					height: 15px;
				}
			}

			h3 {
				max-width: 100%;
				font-size: 16px;
				font-weight: 600;
				color: getColor(grey900);
				margin-bottom: 10;
				white-space: wrap;
				line-height: 24px;
				min-height: 48px;
				height: 48px;
				overflow: hidden;
				text-overflow: ellipsis;

				span {
					color: #a0aec0;
					font-weight: 400;
				}
			}
			.product_title {
				//
				width: 200px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				@supports (-webkit-line-clamp: 2) {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: initial;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}

			.price {
				del {
					color: getColor(grey500);
					font-weight: 400;
					font-size: 12px;
					margin-bottom: 10px;
				}

				h4 {
					font-weight: 700;
					font-size: 14px;
					margin-bottom: 0;
				}

				.addBtn {
					@include absolute(auto, 0, 0, auto);
				}
			}
		}
	}

	.padding0 {
		padding: 10px 0;
	}
	.paddingX {
		padding: 0 10px;
	}

	.product_wrap_ofs {
		opacity: 0.7;
	}
}

.product_wrap_horizontal {
	background: white;
	border-radius: 10px;
	height: 100%;
	position: relative;
	a {
		color: black;
	}
	.productHorizontal {
		// padding: 10px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 100%;

		.img_wrap {
			@include flexCenter;
			position: relative;
			min-height: 254px;
			width: 254px;

			.out_off_stock {
				@include absolute(0, auto, auto, 0);
				background-color: $white;
				z-index: 3;
				font-size: 10px;
				color: $error;
				border-radius: 0px 12px 12px 0px;
				// border: 1px solid $error;
				box-shadow: 1px 0 1px $error;
				padding-left: 0;
				padding: 2px 5px;
				font-weight: 700;
				list-style: none;
			}
			span {
				@include absolute(auto, auto, 0, 0);
				width: 80px;
				height: 22px;
				background: $error;
				border-radius: 0px 12px 12px 0px;
				font-size: 14px;
				color: $white;
				@include flexCenter;
				font-weight: 600;
			}

			img {
				max-height: 186px;
				max-width: 191px;
				border-radius: 10px 10px 0px 0px;
			}
		}

		.product_details {
			padding-top: 10px;
			padding-inline: 30px;
			word-wrap: break-word;

			background-color: #f1f2f4;
			h3 {
				padding: 0 10px;
				max-width: 100%;
				font-size: 16px;
				font-weight: 300;
				@include color(grey900);
				margin-bottom: 10;
				white-space: wrap;
				line-height: 24px;

				span {
					margin-top: 10px;
					@include color(grey500);
				}
			}
			.product_title {
				//
				font-size: 16px;
				width: 150px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				@supports (-webkit-line-clamp: 3) {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: initial;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}

			.price {
				padding: 0 10px;
				del {
					@include color(grey500);
					font-weight: 400;
					font-size: 14px;
				}
				b {
					color: #fd6a6a;
					font-weight: 700;
					font-size: 16px;
				}
				h4 {
					font-weight: 700;
					font-size: 16px;
					margin-bottom: 0;
				}
			}
		}
	}
}

.outofstock {
	position: absolute;
	top: 50%;
	width: 100%;
	padding-inline: 20px;
}
.stocks_message {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #718096;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10px;
	color: #fff;
	font-weight: 600;
	padding: 2px 5px;
}

.price_text_style{

	font-size: 14px;
	font-weight: bold;


	
}

@media (min-width: 576px) {
	.price_text_style {
		font-size: 13px;
		font-weight: bold;
	}
}
@media (min-width: 768px) {
	.price_text_style {
		font-size: 15px;
		font-weight: bold;
	}
}
@media (min-width: 800px) {
	.price_text_style {
		font-size: 15px;
		font-weight: bold;
	}
}
@media (min-width: 1024px) {
	.price_text_style {
		font-size: 13px;
		font-weight: bold;
	}
}

@media (min-width: 1100px) {
	.price_text_style {
		font-size: 13px;
		font-weight: bold;
	
	}
}

@media (min-width: 1200px) {
	.price_text_style {
		font-size: 12px;
		font-weight: bold;
	}
}
@media (min-width: 1280px) {
	.price_text_style {
		font-size: 12px;
		font-weight: bold;
	}
}

@media (min-width: 1400px) {
	.price_text_style {
		font-size: 14px;
		font-weight: bold;
	}
}

/* Extra large devices (1600px and up) */
@media (min-width: 1600px) {
	.price_text_style {
		font-size: 14px;
		font-weight: bold;
	}
}
