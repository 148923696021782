@import '@/services/scss/variables.scss';

.actions_category_side_scroll {
	.card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		border-radius: 6px;
		.card__image {
			border-radius: 6px;
			align-items: center;
			display: flex;
			justify-content: center;

			img {
				width: 113px;
				height: 113px;
				object-fit: cover;
				margin-block: 10px;
			}
		}
		.card__content {
			border-radius: 0 0 6px 6px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			height: 100%;
			padding: 12px;
			gap: 7px;
			width: 100%;

			.card__content__title {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: getColor(grey700);
				font-weight: 600;
				width: 100%;
				text-align: center;
			}
		}
	}
}
