@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
$breakpoint: 768px;
.how_to_order {
	@keyframes pulse-border {
		0% {
			transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
			opacity: 1;
		}
		100% {
			transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
			opacity: 0;
		}
	}
	.videoWrapper {
		position: relative;
		border-radius: 12px;
		display: flex;
		justify-content: center;

		img {
			height: 412px;
		}

		@media screen and (max-width: $breakpoint) {
			img {
				height: 300px;
			}
		}
		.playButton {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100px;
			height: 100px;
			cursor: pointer;

			svg {
				display: block;
				max-width: 100%;
				width: initial;
				height: initial;
				background: none;
				opacity: 1;
				border: 0px;
				margin: 0px;
				padding: 0px;
			}

			//before and after are used to create the triangle pulse effect
			&:before,
			&:after {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translateX(-50%) translateY(-50%);
				display: block;
				width: 150px;
				height: 150px;
				border-radius: 50%;
			}
			&:before {
				z-index: 0;
				background: #ba1f24;
				animation: pulse-border 1.5s ease-out infinite;
			}
			&:after {
				z-index: 1;
				transition: all 0.2s;
			}
			span {
				box-sizing: border-box;
				display: inline-block;
				overflow: hidden;
				width: initial;
				height: initial;
				background: none;
				opacity: 1;
				border: 0px;
				margin: 0px;
				padding: 0px;
				position: relative;
				max-width: 100%;
			}
		}
	}
}
